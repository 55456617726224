<template>
  <div class="container">
    <div class="col-lg-4 col-md-6 ml-auto mr-auto">
      <form @submit.prevent="submit">
        <card class="card-white">
          <template slot="header">
            <h1 class="card-title text-primary">Entrar na MatchJob</h1>
          </template>

          <div>
            <base-input
              required
              v-model="email"
              type="email"
              placeholder="Email"
              addon-left-icon="tim-icons icon-email-85"
              :class="[{ 'has-success': errLogin.status === true }, { 'has-danger': errLogin.status === false }]">
            </base-input>
            <base-input
              required
              v-model="senha"
              placeholder="Password"
              addon-left-icon="tim-icons icon-lock-circle"
              type="password"
              :class="[{ 'has-success': errLogin.status === true }, { 'has-danger': errLogin.status === false }]">
            </base-input>
          </div>
          <div class="row" v-if="!errLogin.status">
            <div class="col-auto">
              <small class="text-danger">{{ errLogin.msg }}</small>
            </div>
          </div>

          <div slot="footer">
            <base-button native-type="submit" type="primary" class="mb-3" size="lg" block>
              Entrar
            </base-button>
          </div>
        </card>
      </form>
    </div>
  </div>
</template>
<script>
import { adminApiUrl, userKey } from "@/global";

export default {
  data() {
    return {
      email: "",
      senha: "",
      errLogin: {
        status: null,
        msg: ''
      },
    };
  },
  methods: {
    submit() {
      //alert("Form has been submitted!");
      const login = {
        client_id: "admincenter",
        client_secret:
          "kb5ycmRlwH213Ofn4Yg1jpcSI6XxWydB9yc0aD921Tx0o4C7lz4EwptymC4y4aKG",
        grant_type: "password",
        username: this.email,
        password: this.senha,
      };
      this.$http
        .post(`${adminApiUrl}/login/entrar`, login)
        .then(res => {
          //console.log('data -> ', res)
          localStorage.setItem(userKey, JSON.stringify(res.data));
          localStorage.setItem('access_token', res.data.full.access_token);
          const dateNow = new Date();
          const dateFinal = new Date(dateNow.getTime() + (parseInt(res.data.full.expires_in) * 1000));
          localStorage.setItem('session_end_datetime', dateFinal);
          setTimeout(() => {
            this.$router.push({ path: "/home/dashboard" }).catch(() => {});
          }, 1000);
        })
        .catch((err) => {
          this.onErrorLogin(err.response.data.error_description);
          console.log("Erro na chamada do login ->> ", err);
        });
    },
    onErrorLogin(value) {
      this.errLogin.msg = value;
      this.errLogin.status = false;
      setTimeout(() => {
      }, 5000);
    }
  }
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
